@import url("https://fonts.cdnfonts.com/css/montserrat");
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

header a img,
.navbar-brand img {
  max-width: 250px;
}

.banner-lead,
header {
  .btn {
    font-family: "Montserrat", Courier;
    font-style: normal;
    font-size: 1.5em;
    font-weight: 600;
    border-color: cadetblue;
    background-color: #0f060c00;
    color: #296e3a;
  }
  .btn-danger {
    margin-left: 20px;
  }
  h1 {
    font-family: "Montserrat", Courier;
    font-style: normal;
    font-size: 4em;
    color: #000000;
    font-weight: 600;
    @media (max-width: 768px) {
      font-size: 3em;
    }
  }
  .dashboard,
  .sign-in {
    font-size: 1em;
    color: cadetblue;
    max-height: 40px;
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.knowledge-section {
  ul {
    text-align: left;
  }
  .homepage-list-header {
    margin-bottom: 40px;
  }
}

nav {
  background-color: #ffffff;
}

.navbar-custom .nav-pills {
  margin-right: 25px;
}
.navbar-custom .nav-link {
  color: cadetblue;
}
.navbar-custom .nav-link.active {
  color: #000000;
  background-color: rgb(255, 255, 255);
}

.footer {
  background-color: #ffffff;
}

p.banner-lead-description {
  font-size: 1.6em;
  color: #000000;
  margin-top: 70px;
  margin-bottom: 40px;
}

.homepage-image {
  border: 5px dashed #106a12;
  height: 100%;
  width: 100%;
  border-radius: 50% 50%;
}

.homepage-image-green {
  background-image: url("../img/homapage-image-green.jpg");
  max-width: 383px;
  min-height: 345px;
  @media (max-width: 768px) {
    max-width: 215px;
    min-height: 215px;
    width: 215px;
    height: 215px;
    background-size: cover;
  }
}

.homepage-image-grey {
  width: 315px;
  max-height: 315px;
  background-image: url("../img/homepage-image-grey.jpg");
  background-position: center;
  background-position-y: 25px;
  background-size: cover;
  border-radius: 50% 50%;
  @media (max-width: 768px) {
    width: 215px;
    max-height: 215px;
  }
}

.feature-list {
  text-align: left;
}

.container {
  max-width: 960px;
}

.site-header {
  background-color: rgba(255, 254, 254, 0.85);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.site-header a {
  color: #8e8e8e;
  transition: color 0.15s ease-in-out;
}

.site-header a:hover {
  color: #fff;
  text-decoration: none;
}

.flex-equal > * {
  flex: 1;
}

@media (min-width: 768px) {
  .flex-md-equal > * {
    flex: 1;
  }
}

#main-menu {
  background-color: #ffffff;
  z-index: 1050; /* Bootstrap's default z-index is 1060 */
}

.left-menu {
  background: #ffffff;
}

.left-menu a img {
  margin: 5px 0;
}

.toolbox {
  padding: 5px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  margin: 5px;
}

.plus {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff), #6b6a6a;
  background-position: center;
  background-size: 50% 5px, 5px 50%;
  background-repeat: no-repeat;
  border-radius: 50%;
  top: 10px;
  left: 10px;
  cursor: pointer;
}

.level-tree {
  border: 1px solid #cccccc;
  border-radius: 3px;
}

.level-tree-content {
  margin: 2px;
  padding: 10px;
}

.level-tree-content a {
  text-decoration: none;
  color: #000000;
  font-weight: bold;
  font-size: 15px;
}

.level-tree-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.level-tree-content ul li ul {
  margin-top: 5px;
}

.level-tree-content span {
  border-bottom: 1px solid;
  border-left: 1px solid;
  height: 1.5rem;
  width: 0.8rem;
  display: block;
  margin-bottom: 0.3rem;
  margin-left: 1rem;
  float: left;
}

.level-tree-content .menu-item {
  padding: 8px 5px;
  transition: background-color 0.3s ease;
  display: flex;
}

.level-tree-content .menu-item:hover {
  cursor: pointer;
  background-color: #eeeeee;
}

.level-tree-content .menu-item-active {
  background-color: #eeeeee;
}

.drawboard {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 2px;
  line-height: 1;
  background: linear-gradient(90deg, white 10px, transparent 1%) center,
    linear-gradient(white 10px, transparent 1%) center, #030303;
  background-size: 11px 11px;
  overflow: hidden;
}

.drawboard-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.drawbord-scroll {
  margin-left: -5000px;
  margin-top: -5000px;
  padding-left: 5000px;
  padding-top: 5000px;
  width: 20000px;
  height: 20000px;
  position: absolute;
  z-index: 1040;
}

.drawbord-content {
  width: 3000px;
  height: 3000px;
  position: absolute;
}

.handler {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 15px;
  height: 15px;
  background-size: contain;
  background-image: url("../img/link2.png");
}

.node {
  position: absolute;
  width: 160px;
  height: 90px;
  background-color: #ffffff;
  -webkit-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.44) !important;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.44) !important;
  border-radius: 3px;
  border: 1px solid #f3f3f3;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.node-hover {
  border: 2px solid #790707;
}

.node .node-title {
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
}

.node .node-title,
.node .node-description {
  padding: 10px;
}

.nodeButton {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  border: 1px solid #e4e4e4;
  background-color: #f3f3f3;
  background-size: 0.8rem 0.8rem;
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  text-decoration: none;
  display: inline-block;
  opacity: 0.5;
  cursor: pointer;
  text-align: center;
  vertical-align: -0.125em;
  position: absolute;
  line-height: 0.6em;
  width: 20px;
  height: 20px;
}

.nodeButton:hover {
  opacity: 1;
}

.relation-node {
  padding: 10px;
  -webkit-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.44) !important;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.44) !important;
  border-radius: 3px;
  border: 1px solid #f3f3f3;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.linkButton {
  background-image: url("../img/link2.png");
  right: 0;
  bottom: 0;
}

.editButton {
  background-image: url("../img/edit.svg");
  right: 30px;
  bottom: 0;
}

.deleteButton {
  background-image: url("../img/trash.svg");
  right: 60px;
  bottom: 0;
  opacity: 0.5;
}

.containerDetailsModal .transformationsTitle {
  text-align: center;
}

.containerDetailsModal .addTransformation {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff), #6b6a6a;
  background-position: center;
  background-size: 50% 2px, 2px 50%;
  background-repeat: no-repeat;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  top: 2px;
}

.containerDetailsModal .description-content {
  pre {
    background-color: black;
    padding: 5px;
    border-radius: 5px;
    code {
      color: white;
      display: block;
      margin: 10px;
    }
  }
}

.node-relation,
.node-transformation {
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  padding: 5px;
  margin-top: 5px;
}

.new-relation-link-container {
  display: flex;
  align-self: center;
  flex: 1;
  position: relative;
}

.new-relation-link {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff), #6b6a6a;
  background-position: center;
  background-size: 50% 2px, 2px 50%;
  background-repeat: no-repeat;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: -8px;
}

.node-relation-title,
.node-transformation-title {
  padding: 5px;
  color: #069286;
  font-weight: bold;
}

.node-api-entry {
  background-color: #eaebf3;
  display: flex;
  margin: 5px 0px 5px 0px;
  border: 1px solid #cecece;
  border-radius: 3px;
}

.node-api-entry p {
  margin-top: 1em;
  margin-bottom: 1em;
}

.api-entry-method {
  padding: 10px;
  margin: 6px;
  font-weight: bold;
  color: #fff;
  min-width: 90px;
  text-transform: uppercase;
  text-align: center;
}

.node-close-button {
  float: right;
}

.api-entry-method-default {
  background-color: #252525;
}

.api-entry-method-get {
  background-color: #2f8132;
}

.api-entry-method-post {
  background-color: #256faf;
}

.api-entry-method-put {
  background-color: #95507c;
}

.api-entry-method-patch {
  background-color: #bf581d;
}

.api-entry-method-delete {
  background-color: #cc3333;
}

#form-file-upload {
  height: 16rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 500px;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 99999;
}

.account-menu {
  border: 1px solid #cccccc;
  border-radius: 3px;
}

.account-menu .user-email {
  overflow: hidden;
  max-width: 200px;
}
.account-menu i {
  font-size: 2rem;
}

#error-message {
  display: none;
}

.homepage-list {
  padding-left: 0px;
  margin-bottom: 40px;

  li {
    list-style: none;
    position: relative;
    padding-left: 45px;
    margin-bottom: 15px;

    &::before {
      content: "✓";
      position: absolute;
      width: 20px;
      height: 20px;
      left: 20px;
      top: 5px;
      transform: translateX(-50%);
      background-color: #008b0c;
      color: #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px !important;
      font-weight: 900;
    }
  }
}
.pricing .card-title {
  color: #b1b1b1;
}
.pricing .card-title span.plan-price {
  font-size: 2em;
  font-weight: 400;
  color: #000000;
}

.social-login {
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 10px;
}
.social-login h4,
.login-page h2 {
  color: #6c869d;
  margin: 1em 0px;
  text-align: center;
}
.social-link {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.google-icon {
  width: 20px;
  margin: 0 10px 0 0;
}
.social-link a {
  text-decoration: none;
  color: black;
}

.footer {
  position: fixed;
  height: 100px;
  bottom: 0;
  width: 100%;
}

.homepage-footer {
  position: relative !important;
}
